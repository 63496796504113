<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>COMISIONES: {{ sellerInfo.name }} {{ sellerInfo.lastname }}</h3> <small> (Hay {{ totalCommissions }} ventas)</small>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
            <!--            <b-form-input-->
            <!--              v-model="searchQuery"-->
            <!--              class="d-inline-block mr-1"-->
            <!--              placeholder="Buscar por N° de guía,cuenta o N° de factura"-->
            <!--            />-->
            <flat-pickr
              v-model="filterDate"
              class="form-control mr-1"
              :config="{ enableTime: false, dateFormat: 'Y-m',locale: locale}"
            />
            <!--            <b-button-->
            <!--              variant="primary"-->
            <!--              class="mr-1"-->
            <!--            >-->
            <!--              <span class="text-nowrap">Buscar venta</span>-->
            <!--            </b-button>-->
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refCommissionListTable"
      :items="fetchCommissionForSeller"
      responsive
      :fields="tableColumnsCommission"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Id -->

      <!-- Column: Sale Code -->
      <template #cell(sale_code)="data">
        <b-media vertical-align="center">
          <b-link
            class="alert-link font-weight-bold d-block text-nowrap"
            @click="() => openPDF(data.item)"
          >
            {{ data.item.sale_code }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(subtotal)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.subtotal }}
        </span>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(total)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.total }}
        </span>
      </template>

      <!-- Column: Commission -->
      <template #cell(commission)="data">
        <span
          :id="`commission-text-${data.item.id}`"
          class="text-nowrap"
        >
          {{ data.item.commission ? `${data.item.commission}% = ${data.item.currency_symbol}${(data.item.subtotal*data.item.commission/100).toFixed(2)}` : 'Sin comision' }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <!--          <feather-icon-->
          <!--            :id="`invoice-row-${data.item.id}-view-icon`"-->
          <!--            icon="PlusIcon"-->
          <!--            class="cursor-pointer"-->
          <!--            size="16"-->
          <!--          />-->
          <!--          <b-tooltip-->
          <!--            title="Agregar comision"-->
          <!--            class="cursor-pointer"-->
          <!--            :target="`invoice-row-${data.item.id}-view-icon`"-->
          <!--          />-->

          <feather-icon
            v-if="!data.item.commission"
            :id="`popover-button-variant-${data.item.id}`"
            icon="PlusSquareIcon"
            class="cursor-pointer"
            size="16"
            @click="clearInputCommission"
          />

          <b-popover
            :target="`popover-button-variant-${data.item.id}`"
            variant="primary"
            triggers="focus"
            placement="left"
          >
            <template #title>
              <span>COMISIÓN(%)</span>
            </template>
            <span class="mb-1">{{ data.item.currency_symbol }} {{ (data.item.subtotal * commissionValue/100).toFixed(2) }}</span>

            <b-form-group
              label-for="popover-input-1"
              :state="inputCommissionState"
              class="mb-1"
              invalid-feedback="Este campo es requerido"
            >
              <b-form-input
                id="popover-input-1"
                ref="input1"
                v-model.number="commissionValue"
                type="number"
                :state="inputCommissionState"
                size="sm"
              />
            </b-form-group>
            <b-button
              variant="primary"
              class="mr-1"
              size="sm"
              @click="saveCommission(data.item)"
            >
              <span class="text-nowrap">Guardar</span>
            </b-button>
          </b-popover>

          <feather-icon
            v-if="data.item.commission"
            :id="`popover-button-variant-${data.item.id}`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="clearInputCommission"
          />
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCommissions"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BLink,
  BCard,
  BCol,
  BMedia, BPagination,
  BRow,
  BTable,
  BPopover,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import commissionHandler from '@/views/dramox/commission/commissionHandler'
import commissionStoreModule from '@/views/dramox/commission/commissionStoreModule'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import { Spanish as SpanishLocale } from 'flatpickr/dist/l10n/es.js'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'HomeBusiness',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BPagination,
    BLink,
    BPopover,
    BFormGroup,
    BFormInput,

    flatPickr,
  },
  data() {
    return {
      locale: SpanishLocale,
      options: {
        number: {
          numeral: true,
        },
      },
    }
  },
  setup() {
    const BUSINESS_APP_STORE_MODULE_NAME = 'app-commissions'

    // Register module
    if (!store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.registerModule(BUSINESS_APP_STORE_MODULE_NAME, commissionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BUSINESS_APP_STORE_MODULE_NAME)) store.unregisterModule(BUSINESS_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const commissionValue = ref(null)
    const inputCommissionState = ref(null)
    const popoverShow = ref(false)
    const currentPage = ref(1)
    const sellerInfo = ref({ })
    const totalCommissions = ref(0)
    const perPage = ref(10)
    const refCommissionListTable = ref(null)
    const filterDate = ref('')

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/pdf/quotation/${val.id}`
      window.open(url, '_blanck')
    }

    const refetchData = () => {
      refCommissionListTable.value.refresh()
    }

    const clearInputCommission = () => {
      commissionValue.value = null
      inputCommissionState.value = null
    }

    const saveCommission = item => {
      if (!commissionValue.value) {
        inputCommissionState.value = false
        return
      }

      store.dispatch('app-commissions/addSellerCommission', {
        body: {
          saleId: item.id,
          commission: commissionValue.value,
        },
      })
        .then(() => {
          showNotification({ title: 'Comision creada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
          refetchData()
          popoverShow.value = false
        })
        .catch(error => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const {
      tableColumnsCommission,
      searchQuery,
    } = commissionHandler()

    const fetchCommissionForSeller = (ctx, callback) => {
      store.dispatch('app-commissions/fetchCommissionForSeller', {
        id: router.currentRoute.params.id,
        queryParams: {
          page: currentPage.value,
          date: filterDate.value,
        },
      })
        .then(response => {
          const { seller, sales } = response.data.payload.results
          callback(sales.data)
          totalCommissions.value = sales.total
          sellerInfo.value = seller
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    watch([currentPage, filterDate], () => {
      refetchData()
    })

    watch([commissionValue], () => {
      inputCommissionState.value = true
    })

    return {
      tableColumnsCommission,
      currentPage,
      totalCommissions,
      searchQuery,
      perPage,
      refCommissionListTable,
      refetchData,
      sellerInfo,
      openPDF,
      fetchCommissionForSeller,
      clearInputCommission,
      commissionValue,
      filterDate,

      saveCommission,
      inputCommissionState,
      popoverShow,
      // businessData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
